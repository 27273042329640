@import './mq.scss';

h1 {
  font-size: 3rem;
  @include breakpoint(lg) {
    font-size: 3.5rem;
  }
}

h2 {
  font-size: 2rem;
  @include breakpoint(lg) {
    font-size: 2.6rem;
  }
}

h3 {
  font-size: 1.75rem;
  @include breakpoint(lg) {
    font-size: 2rem;
  }
}

h4 {
  font-size: 1.2rem;
  @include breakpoint(lg) {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1rem;
  @include breakpoint(lg) {
    font-size: 1.25rem;
  }
}

h6 {
  font-size: 0.8rem;
  @include breakpoint(lg) {
    font-size: 1rem;
  }
}

p,
span,
q {
  font-size: 1rem;
  line-height: 1.4;
  font-family: var(--font-family);
  @include breakpoint(lg) {
    line-height: 1.6;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: 1.6;
}

a {
  font-size: 0.8rem;
}
