.graph {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 10px;
}

.graph-bar {
  width: 70px;
  height: 200px;
  background-color: var(--color-black);
  border-radius: 5px;
  margin-top: 100px;
  z-index: 2;
  position: relative;
}
.graph-bar:first-child {
  height: 100px;
  background-color: var(--color-black);
}

.graph-bar:nth-child(2) {
  height: 150px;
  background-color: var(--color-black);
}

.graph-bar:nth-child(3) {
  height: 250px;
  background-color: var(--color-black);
}
.graph-bar span {
  position: absolute;
  color: var(--color-black);
  top: -40px;
  left: 20px;
  font-size: 1.4rem;
}
