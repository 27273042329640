@import '../../styles/mq.scss';
$footerColor: transparent;

footer {
  padding-bottom: 100px;
  padding-top: 150px;
  background-color: $footerColor;
  color: var(--color-black);

  @include breakpoint(lg) {
    padding-top: 50px;
  }

  p {
    font-size: 1rem;

    .icon {
      vertical-align: middle;
    }
  }

  li {
    font-size: 1rem;
  }
}
