@import './transitions.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-optical-sizing: auto;
  font-style: normal;
  overflow-x: hidden;
  background-color: var(--color-pink-light);
}

* {
  box-sizing: border-box;
}

.loader {
  border-radius: var(--border-radius);
  position: absolute;
  z-index: 9;
  background-color: var(--color-pink);
  inset: 0;
  color: var(--color-white);
  border: 4px solid var(--color-pink);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fadeOut {
  animation: fadeOut 1s ease-in-out forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
