@import './../../../../styles/mq.scss';

.project-header {
  .flex {
    & > div {
      width: 100%;

      @include breakpoint(lg) {
        flex: 1;
        width: auto;

        &:last-child {
          flex: 2;
        }
      }
    }
  }
}

.fake-browser {
  position: relative;
  min-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  border: 5px solid var(--color-black);
  display: flex;
  flex-flow: column;

  .bar {
    width: 100%;
    height: 50px;
    background-color: var(--color-black);
    display: flex;
    align-items: center;
    padding-left: 20px;

    &::after {
      content: '';
      width: 100px;
      height: 15px;
      background-color: var(--color-accent);
      border-radius: 10px;
      margin-right: 10px;
      margin-left: 20px;
    }

    &::before {
      content: '';
      width: 100px;
      height: 15px;
      background-color: var(--color-accent);
      border-radius: 10px;
      margin-right: 0px;
    }
  }

  .fake-content {
    height: 100%;
    background-color: var(--color-white);
    border-radius: 0 0 10px 10px;
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
  }

  .fake-text {
    width: 50%;
    height: 20px;
    background-color: var(--color-gray);
    border-radius: 10px;
    position: absolute;
    top: 40px;

    &:nth-child(2) {
      width: 20%;
      top: 80px;
    }

    &:nth-child(3) {
      width: 50%;
      top: 120px;
    }

    &:nth-child(4) {
      width: 40%;
      top: 160px;
    }

    &:nth-child(5) {
      width: 50%;
      top: 200px;
    }
  }
}
