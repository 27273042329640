$transitionDuration: 0.3s;
$timingFunction: ease-in-out;

@mixin transition(
  $properties,
  $duration: $transitionDuration,
  $timing: $timingFunction
) {
  @for $i from 1 through length($properties) {
    transition: nth($properties, $i) $duration $timing;
  }
}
