@import './../../../../../styles/transitions.scss';
@import './../../../../../styles/mq.scss';
.dashboard-wrapper {
  padding: var(--card-spacing);
  padding-bottom: 0;
}

.video-dashboard {
  .dashboard {
    min-height: 50vh;
  }
}

.dashboard {
  position: relative;
  background-color: #f5f5f5;
  display: flex;
  height: calc(100% - 50px); // height of top bar in nav
  flex: 1;
  flex-flow: column;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  aside {
    width: 200px;
    top: 0;
    left: 0;
    background-color: var(--color-brown);
    position: absolute;
    padding: var(--card-spacing);
    height: 100%;
    border-top-left-radius: var(--border-radius);
  }

  aside a {
    color: var(--color-white);
    text-decoration: none;
    font-size: 1rem;
    @include transition(color);

    &:hover {
      color: var(--color-accent);
    }
  }

  aside ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 1rem;
    }
  }

  header {
    padding: 0;
    margin-bottom: 10px;

    input {
      border: none;
      background-color: var(--color-white);
      width: 100%;
      color: var(--color-black);
      font-size: 0.9rem;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 10px;
      border-radius: var(--border-radius);
      ::placeholder {
        color: var(--color-black);
      }

      :focus {
        outline: none;
      }
    }
  }

  main {
    height: 100%;
    margin-left: 200px;
    padding: 1.2rem;
    background-color: #fafafa;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    border-top-right-radius: var(--border-radius);

    @include breakpoint(md) {
      width: calc(100% - 200px);
    }
  }

  .stretch {
    flex: 1 1 auto;
  }

  .content {
    padding: 2rem;
    flex: 1;
    background-color: var(--color-brown);
    border: 3px dashed var(--color-black);
    border-radius: 10px;
  }

  .content--left {
    flex-grow: 2;
  }

  @media (max-width: 768px) {
    aside {
      width: 100%;
      position: relative;
      padding: 1rem;
      height: auto;
    }

    aside a {
      font-size: 1rem;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    aside li {
      margin-bottom: 0.5rem;
    }

    main {
      margin-left: 0;
      flex-direction: column;
      padding-top: 20px;
    }
  }
}
