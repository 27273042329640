@import '../../../../styles/mq.scss';
@import '../../../../styles/transitions.scss';

iframe {
  border: none;
  border-radius: var(--border-radius);
}

.codepen {
  margin-top: 50px;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
  }
  button {
    position: relative;
    width: 80%;
    @include transition(transform);

    &:hover,
    &.active {
      transform: translateX(10px);
      background-color: var(--color-pink-dark);
    }

    .icon {
      color: var(--color-white);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      font-size: 3rem;
    }
  }

  .preview {
    width: 100%;
    max-height: 500px;
    margin-top: 50px;
    margin-bottom: 100px;
    position: relative;

    @include breakpoint(lg) {
      width: 80%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
