@import './../../styles/mq.scss';
@import './../../styles/transitions.scss';

.navigation {
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  z-index: 10;
  background-color: var(--color-pink);
  margin: 0 auto;
  transition:
    width 0.3s ease-in-out,
    top 0.3s ease-in-out,
    border-radius 0.3s ease-in-out;
  width: 100%;
  border-radius: 0;
  overflow: hidden;

  &.scrolled-to-top {
    top: 20px;
    width: 90%;
    border-radius: var(--border-radius);
  }

  .nav-link {
    padding: 20px;
    border-radius: 0;
  }
}
