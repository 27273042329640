.board {
  border-radius: 10px;
  border: 6px solid var(--color-black);
  width: 300px;
  height: 200px;
  position: absolute;
  background-color: var(--color-gray);
  display: flex;
  right: 20px;
  top: 20%;

  header {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-black);
    height: 10px;
    width: 100px;
    padding: 0;
    top: -10px;
  }

  .sponge {
    background-color: var(--color-accent);
    width: 60px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 5px;
    border-radius: 5px;
  }

  .legs {
    position: relative;
    top: 100%;
    margin: 0 auto;
  }

  .leg {
    position: absolute;
    background-color: var(--color-black);
    width: 10px;
    height: 70px;
    border-radius: 5px;
    top: -10px;
    transform: rotate(30deg) translateX(-20px) translateY(20px);

    &:last-child {
      transform: rotate(-30deg) translateX(0%) translateY(10px);
    }
  }
}
