@import '../../../../styles/mq.scss';
@import '../../../../styles/transitions.scss';
.aside-section {
  aside {
    background-color: var(--color-pink-light);
    right: 0;
    z-index: 8;
    width: 100%;
    padding: 20px;
    transform: translateX(110%);
    @include transition(transform);
    color: var(--color-black);

    bottom: 0;
    max-height: fit-content;
    @include breakpoint(md) {
      position: absolute;
      top: 0;
      width: 400px;
    }

    @include breakpoint(lg) {
      width: 500px;
    }
    &.show {
      transform: translateX(0px);
    }
  }
}
