@import '../styles/mq.scss';

.container {
  max-width: 1200px;
  margin: 0px auto;
  position: relative;
  padding: 0 20px;
  @include breakpoint('xl') {
    padding: 0;
  }
}

.small-container {
  max-width: 1000px;
  margin: 0px auto;
  position: relative;
  padding: 0 20px;
  @include breakpoint('xl') {
    padding: 0;
  }
}
