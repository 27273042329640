@import './../../styles//mq.scss';

.search-container {
  padding-top: 120px;
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9;
  display: none;
  backdrop-filter: blur(5px);
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  max-height: 100vh;

  form {
    width: 600px;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);

    svg {
      right: 10px;
      top: 15px;
    }
  }

  &.show {
    display: flex;
  }

  input {
    width: 100%;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border: none;
    font-size: 1rem;
    padding: 15px 30px 15px 20px;

    &:focus {
      outline: none;
    }
  }

  .search-results {
    background-color: var(--color-black);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);

    p {
      padding: 10px 20px;
    }
  }

  input[type='submit'] {
    border-radius: 0;
    font-size: 1rem;
  }

  button {
    top: 20px;
    left: 20px;
  }

  .navigation-button {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}
