@import './mq.scss';

:root {
  --section-y-spacing: 1.5rem;
  --button-spacing: 1rem;
  --border-radius: 5px;
  --card-spacing: 1rem;
  --grid-gap: 20px;
  --small-grid-gap: 15px;
  --small-margin: 0.5rem;
}

@include breakpoint('md') {
  :root {
    --section-y-spacing: 2rem;
    --button-spacing: 1.5rem;
    --card-spacing: 1.5rem;
    --grid-gap: 30px;
    --small-margin: 1rem;
  }
}

@include breakpoint('lg') {
  :root {
    --section-y-spacing: 2.8rem;
    --button-spacing: 1.2rem;
    --card-spacing: 1.8rem;
    --grid-gap: 40px;
    --small-margin: 1.5rem;
  }
}

@include breakpoint('xl') {
  :root {
    --section-y-spacing: 4rem;
  }
}

section {
  padding: var(--section-y-spacing) 0;
}

section h2 {
  padding-top: var(--section-y-spacing);
  padding-bottom: var(--section-y-spacing);
}
