@import './../../../styles/mq.scss';
.mobile-nav {
  width: 100%;
  @include breakpoint(md) {
    max-width: 300px;
  }
  .links {
    a,
    button:not(.normal-font) {
      font-size: 2rem;
    }
  }

  .cta-section {
    margin-top: 0;
  }
}
