@import './transitions.scss';

button {
  display: inline-block;
}

button,
a[role='button'],
*[role='button'],
input[type='submit'] {
  font-family: var(--font-family);
  padding: calc(var(--button-spacing) / 2) var(--button-spacing);
  border-radius: var(--border-radius);
  border: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out,
    outline 0.3s ease-in-out;
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.primary {
    background-color: var(--color-pink);
    color: var(--color-white);

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--color-pink-dark);
    }
  }

  &.secondary {
    background-color: var(--color-white);
    color: var(--color-black);

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--color-sky-blue);
      color: var(--color-black);
    }
  }

  &.ternary {
    background-color: var(--color-sky-blue);
    color: var(--color-black);

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--color-pink);
      color: var(--color-white);
    }
  }

  &.transparent {
    background-color: transparent;
    color: var(--color-black);

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--color-purple);
    }
  }

  &.dark {
    background-color: #000;
    color: var(--color-white);
  }
}
