@import '../../../../styles//mq.scss';
@import '../../../../styles/transitions.scss';

.question {
  background-color: var(--color-black);
  border-radius: 5px;
  color: var(--color-white);
  cursor: pointer;
  opacity: 0;
  @include transition(opacity);

  &:hover {
    background-color: var(--color-accent);
  }
}

@for $i from 1 through 5 {
  .question:nth-child(#{$i}) {
    animation: fadeIn 0.5s #{$i * 0.2}s forwards;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
