.laptop {
  background-color: var(--color-pink);
  width: 100px;
  height: 70px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  top: 47px;
}

.laptop::after {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background-color: var(--color-pink);
  border-top-left-radius: 5px;
  transform: skewX(5deg);
  left: -5px;
  position: absolute;
}

.laptop::before {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background-color: var(--color-pink);
  border-top-right-radius: 5px;
  transform: skewX(-5deg);
  right: -5px;
  position: absolute;
}

.face {
  background-color: #ffc92e;
  height: 70px;
  width: 90px;
  margin: 0 auto;
  border-radius: 30%;
  position: relative;
}

.wrapper {
  max-width: 100px;
  position: relative;
  display: inline-block;
}

.eye {
  width: 25px;
  height: 20px;
  position: absolute;
  background-color: #fff;
  border-radius: 70% 0px;
  top: 40%;
  transform: translateY(-50%) rotate(35deg);
  padding: 3px;
}

.eye-left {
  left: 10px;
}

.eye-left .eyebrow {
  width: 50px;
  height: 40px;
  border: solid 5px #1a1e27;
  border-color: #1a1e27 transparent transparent transparent;
  border-radius: 50%/40px 40px 0 0;
  position: absolute;
  top: -5px;
  left: -11px;
  transform: rotate(-40deg);
}

.eye-right .eyebrow {
  width: 50px;
  height: 40px;
  border: solid 5px #1a1e27;
  border-color: #1a1e27 transparent transparent transparent;
  border-radius: 50%/40px 40px 0 0;
  position: absolute;
  top: -5px;
  left: -11px;
  transform: rotate(-40deg);
}

.eye-left::after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-color: #400805;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eye-left::before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  left: 12px;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.eye-right {
  right: 10px;
}

.eye-right::after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-color: #400805;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eye-right::before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  left: 12px;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.body {
  width: 8rem;
  height: 3rem;
  background-color: var(--color-black);
  border-radius: 5rem 5rem 0 0;
  position: relative;
  left: -15%;
}

.hair {
  height: 20px;
  position: absolute;
  width: 100%;
  bottom: 50%;
  left: -40px;
}
.hair::before,
.hair::after {
  content: '';
  position: absolute;
}
.hair::before {
  border-bottom: 5px solid #1a1e27;
  border-left: 5px solid #1a1e27;
  border-radius: 1rem;
  bottom: 0;
  height: 50px;
  left: 35%;
  transform: rotate(25deg);
  width: 1rem;
}
.hair::after {
  border-right: 5px solid #1a1e27;
  border-top: 5px solid #1a1e27;
  border-radius: 1rem;
  bottom: 0.65rem;
  height: 50px;
  left: 27%;
  transform: rotate(30deg);
  width: 1rem;
}

.hair:nth-child(2) {
  left: -30px;
}

.hair:nth-child(3) {
  left: -20px;
}

.straight-hair {
  height: 50px;
  position: absolute;
  width: 100%;
  left: 0px;
  top: -40px;
  background-color: #1a1e27;
  border-radius: 50%;
  z-index: 1;
}

.straight-hair::after {
  content: '';
  display: block;
  width: 100%;
  height: 0px;
  background-color: #1a1e27;
  border-radius: 50%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.straight-hair::before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  background-color: #1a1e27;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
