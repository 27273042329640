.logo {
  font-weight: 500;
  font-style: normal;
  color: var(--color-black);
}

.orbitron {
  font-family: 'Orbitron', sans-serif;
  font-optical-sizing: auto;
}
