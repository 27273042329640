@import './styles/variables.scss';
@import './styles/typography.scss';
@import './styles/global.scss';
@import './styles/containers.scss';
@import './styles/utils.scss';
@import './styles/tooltip.scss';
@import './styles/mq.scss';
@import './styles/selection.scss';
@import './styles/spacings.scss';
@import './styles/buttons.scss';
@import './styles/transitions.scss';
@import './styles/dark_theme.scss';

main {
  padding-bottom: var(--small-margin);
}
