::selection {
  background-color: var(--color-pink);
  color: var(--color-white);
}

.bg-pink {
  ::selection {
    background-color: var(--color-white);
    color: var(--color-black);
  }
}
