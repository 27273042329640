@import '../../../styles/mq.scss';

.modal {
  outline: none;
  max-width: 80%;
  margin: 0 auto;
  .content {
    padding-bottom: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 20px auto;

    @include breakpoint(lg) {
      margin-top: 100px;
    }

    &.contain {
      background-size: contain;
      min-height: 80vh;
      @include breakpoint(lg) {
        min-height: auto;
      }
    }
  }

  p {
    text-align: center;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
}

.masonry-item {
  img {
    width: 100%;
    object-fit: cover;
  }
}
