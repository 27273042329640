@import './../../styles/transitions.scss';

.icons {
  a {
    color: var(--color-black);
    @include transition(transform);
  }

  a:hover {
    transform: translateY(-5px);
  }
}
